.a-link {
  cursor: pointer;
}
span.a-link:hover {
  text-decoration: underline;
}

.typesOfAccidentSummary {
  min-height: 420px;
}

.total-border {
  border: none !important;
}

.clear-filter-btn {
  color: #000000;
  background-color: #fff;
}
.search-btn {
  color: #fff;
}
.filter-container {
  background-color: #e9e9e9;
}
