.a-link {
  cursor: pointer;
}

span.a-link:hover {
  text-decoration: underline;
}

.totals-text {
  font-weight: bold;
  font-size: 1.1em;
}

.filter-btn {
  width: 100%;
}

.law-firm-link {
  cursor: pointer;
  color: blue;
  font-weight: bold;
  font-size: 1.1em;
  text-decoration: underline;
}

.dropdown-content {
  width: 350px;
}

.filter-height {
  min-height: 220px;
}

.filter-btn {
  width: 100%;
}

.clear-filter-button {
  color: #000000;
  background: #ffffff;
}

.search-btn {
  color: #ffffff;
}

.MuiTableHead-root th {
  text-transform: uppercase;
}

.MuiTableBody-root td {
  vertical-align: top;
}

.lfsr-table-container {
  max-width: 600px;
}

.lfsr-filter-container {
  background-color: #e9e9e9;
}

.main-container {
  max-width: 95%;
}

/* TABLE COLUMNS  */
.law-firm {
  max-width: 200px;
  min-width: 200px;
}

.num-cases {
  max-width: 100px;
  min-width: 100px;
  text-align: end !important;
}

.total-advanced-amt {
  max-width: 140px;
  min-width: 140px;
  text-align: end !important;
}
