.listing-rows {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.casevalue-table-col {
  border: 1px solid #000000;
}

.tb-hdng {
  text-align: center;
}

.bold-span {
  font-weight: bold;
}

.number-input {
  text-align: right;
}

.concession-form {
  max-width: 1100px;
}

.first-half {
  max-width: 370px;
}

.concession-date-datepicker {
  max-width: 150px;
}
