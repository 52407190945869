.a-link {
  cursor: pointer;
}

span.a-link:hover {
  text-decoration: underline;
}

.clear-filter-btn {
  color: #000000;
  background-color: #fff;
}

.search-btn {
  color: #fff;
}

.acc-man-filter-container {
  background-color: #e9e9e9;
}

.main-container {
  max-width: 95%;
}

.export-btn {
  width: 100px;
}

/* TABLE COLUMNS  */
.amd-acc-manger-clmn {
  max-width: 110px;
  min-width: 110px;
}

.amd-client-clmn {
  max-width: 100px;
  min-width: 100px;
}

.amd-attorney-clmn {
  max-width: 90px;
  min-width: 90px;
}

.amd-status-clmn {
  max-width: 75px;
  min-width: 75px;
}

.amd-appNo-clmn {
  max-width: 75px;
  min-width: 75px;
}

.amd-advancedDt-clmn {
  max-width: 110px;
  min-width: 110px;
}

.amd-payoffdt-clmn {
  max-width: 100px;
  min-width: 100px;
}

.amd-othrdisbs-clmn {
  max-width: 120px;
  min-width: 120px;
  text-align: end !important;
}

.amd-ctc-clmn {
  max-width: 115px;
  min-width: 115px;
  text-align: end !important;
}

.amd-comAdv-clmn {
  max-width: 130px;
  min-width: 130px;
  text-align: end !important;
}

.amd-npffee-clmn {
  max-width: 90px;
  min-width: 90px;
}

.amd-portfolio-clmn {
  max-width: 100px;
  min-width: 100px;
}

.amd-paymntconf-clmn {
  max-width: 120px;
  min-width: 120px;
}
