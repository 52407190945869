.highlight-header {
  background-color: black;
}
.highlight-row {
  background-color: #f2f2f2;
}
.highlight-text {
  font-weight: bold !important;
}
.table-header {
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;
  margin-bottom: 0;
  padding: 3px;
}
