.a-link {
  cursor: pointer;
}

span.a-link:hover {
  text-decoration: underline;
}

.totals-text {
  font-weight: bold;
  font-size: 1.1em;
}

.table-boder {
  border: none !important;
}

.clear-filter-button {
  color: #000000;
  background: #ffffff;
}

.search-btn {
  color: #ffffff;
}

.MuiTableHead-root th {
  text-transform: uppercase;
}

.MuiTableBody-root td {
  vertical-align: top;
}

.lfd-export-button {
  background-color: var(--green);
  border-color: var(--green);
  width: 100px;
}

/* TABLE COLUMNS  */
.client-column {
  min-width: 110px;
  max-width: 110px;
}

.appNumber-column {
  min-width: 80px;
  max-width: 80px;
}

.advanced-date-column {
  min-width: 110px;
  max-width: 110px;
}

.accident-date-column {
  min-width: 110px;
  max-width: 110px;
}

.lfdr-advanced-amount-column {
  min-width: 110px;
  max-width: 110px;
  text-align: end !important;
}

.lfdr-total-cash-outlay-column {
  min-width: 110px;
  max-width: 110px;
  text-align: end !important;
}

.days-outstanding-column {
  min-width: 110px;
  max-width: 110px;
}

.attorney-column {
  min-width: 110px;
  max-width: 110px;
}
