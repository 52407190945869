#payoffStatic {
  width: 2200px;
  table-layout: fixed;
}

#payoffStatic thead th {
  vertical-align: bottom;
  text-align: right;
  padding: 0.1rem;
}

#payoffStatic td {
  vertical-align: middle;
  text-align: right;
}

#payoffStatic .topth {
  text-align: center;
}

#payoffStatic .firstCol {
  text-align: left;
  width: 100px;
}

#payoffStatic .wideCol {
  width: 150px;
}

.top5 {
  margin-top: 5px;
}
.top7 {
  margin-top: 7px;
}
.top10 {
  margin-top: 10px;
}
.top15 {
  margin-top: 15px;
}
.top17 {
  margin-top: 17px;
}
.top30 {
  margin-top: 30px;
}
