.listing-rows {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.casevalue-table-col {
  border: 1px solid #000000;
}

.tb-hdng {
  text-align: center;
}

.bold-span {
  font-weight: bold;
}

.new-payoff-form {
}

.form-first-half-section {
  max-width: 350px;
}

.date-of-payoff {
  max-width: 150px;
}

.amount {
  max-width: 140px;
}

.amount-received {
  max-width: 100px;
}

.marketing-expenses {
  max-width: 100px;
  margin-bottom: 10px;
}

/* .notes-textarea {
  max-width: 350px;
} */

.underlined-col {
  border-bottom: 1px solid #000;
}

.number-input {
  text-align: right;
}
