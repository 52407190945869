.has-case-settled-select {
  max-width: 85px;
}

.case-settlement-options-select {
  max-width: 300px;
}

.payoff-good-until-datepicker {
  max-width: 200px;
}

.notes-textarea {
  max-width: 300px;
}
