.margin-heading {
  margin-left: 15px;
}

.padding-left {
  padding: '0.5rem';
}

.MuiTableHead-root th {
  text-transform: uppercase;
}

.MuiTableBody-root td {
  vertical-align: top;
}

.clear-filter-btn {
  color: #000000;
  background: #ffffff;
}

.search-btn {
  color: #ffffff;
}

.filter-container {
  background-color: #e9e9e9;
  margin-right: 10px;
}

.fc-table-container {
  max-width: 900px;
}

/* TABLE COLUMNS  */
.financial-store-name {
  min-width: 175px;
  max-width: 175px;
}

.store-number {
  min-width: 80px;
  max-width: 80px;
}

.store-location {
  min-width: 110px;
  max-width: 110px;
}

.total-advances {
  min-width: 110px;
  max-width: 110px;
}

.fcr-total-delivery-fees {
  min-width: 130px;
  max-width: 130px;
  text-align: end !important;
}

.last-transaction {
  min-width: 110px;
  max-width: 110px;
}
