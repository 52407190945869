.a-link {
  cursor: pointer;
}

span.a-link:hover {
  text-decoration: underline;
}

.totals-text {
  font-weight: bold;
  font-size: 1.1em;
}

.export-button {
  width: 100%;
}

.filter-block {
  max-width: 300px;
}

.clear-filter-button {
  color: #000000;
  background: #ffffff;
}

.search-btn {
  color: #ffffff;
}

/* Table columns  */
.adr-total-payouts {
  max-width: 110px;
  min-width: 110px;
  text-align: end !important;
}
