.a-link {
  cursor: pointer;
}

span.a-link:hover {
  text-decoration: underline;
}

.totals-text {
  font-weight: bold;
  font-size: 1.1em;
}

.filter-btn {
  width: 100%;
}

.MuiTableHead-root th {
  text-transform: uppercase;
}

.MuiTableBody-root td {
  vertical-align: top;
}

.ams-table-container {
  max-width: 600px;
}

.clear-filter-btn {
  color: #000000;
  background: #ffffff;
}

.search-btn {
  color: #ffffff;
}

.ams-filter-container {
  background-color: #e9e9e9;
}

.main-container {
  max-width: 95%;
}

/* table columns */
.name-column {
  min-width: 200px;
  max-width: 200px;
}

.num-of-advances {
  min-width: 80px;
  max-width: 80px;
}

.ams-nfpfees {
  min-width: 80px;
  max-width: 80px;
  text-align: end !important;
}

.ams-commadv {
  min-width: 150px;
  max-width: 150px;
  text-align: end !important;
}
