.a-link {
  cursor: pointer;
}

span.a-link:hover {
  text-decoration: underline;
}

.table-totals-all-states {
  max-width: 530px;
  min-width: 530px;
}

.MuiTableHead-root th {
  text-transform: uppercase;
}

.MuiTableBody-root td {
  vertical-align: top;
}

.fin-summary-filtering-section {
  background-color: #e9e9e9;
}

.fin-smry-table-container {
  max-width: 550px;
}

.clear-filter-button {
  color: #000000;
  background: #ffffff;
}

.search-btn {
  color: #ffffff;
}

/* Table columns  */
.fsr-state-name {
  min-width: 200px;
  max-width: 200px;
}

.fsr-total-advances {
  min-width: 90px;
  max-width: 90px;
}

.fsr-total-present-value {
  min-width: 100px;
  max-width: 100px;
  text-align: end !important;
}
